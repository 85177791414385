@use "~@mozilla-protocol/core/protocol/css/includes/lib" as *;
@use "../../../styles/color";
@use "../../../styles/text";

.mobile-menu {
  width: 100%;
  position: absolute;
  // z-index of 1 (so it overlaps content)
  // allows menu to slide under main navigation header
  z-index: 1;
  max-height: calc(100vh - 100%);
  overflow-y: auto;

  &.not-active {
    display: none;
  }
}

#mobile-menu {
  transform: translateY(-100%);
  background-color: $color-white;
  box-shadow: $box-shadow-lg;
  width: 100%;
  // Prevent the menu from being shown while collapsed;
  // otherwise it might get exposed when the browser "overscrolls":
  opacity: 0;

  &.not-active {
    animation: slide-out 300ms cubic-bezier(0.52, -0.14, 0.31, 1.2);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &.is-active {
    animation: slide-in 450ms cubic-bezier(0.52, -0.14, 0.31, 1.2);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @media (prefers-reduced-motion) {
    &.not-active {
      animation: none;
    }
    &.is-active {
      animation: none;
    }
  }

  @keyframes slide-in {
    0% {
      // Make sure the menu is visible while sliding in:
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-out {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    99% {
      // Make sure the menu doesn't disappear until it is behind the header:
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      // Hide the menu again, so it's not revealed when the browser "overscrolls":
      opacity: 0;
    }
  }
}

.menu-item-list {
  @media screen and #{$mq-md} {
    display: none;
  }
}

.menu-item {
  display: flex;

  @include text.display-xxs {
    font-family: $font-stack-firefox;
    border-bottom: 2px solid $color-light-gray-20;
  }

  &.sign-up-menu-item {
    padding: $spacing-md;
  }

  .link {
    display: flex;
    border: none;
    background-color: transparent;
    padding: $spacing-lg $spacing-md;
    width: 100%;
    flex-direction: row;
    gap: $spacing-md;
    font-weight: 500;
    color: color.$grey-40;
    cursor: pointer;

    @media screen and (max-height: 700px) {
      // Because the menu is not scrollable (it stays in the same position while
      // the content behind it scrolls), this makes it take up less space on
      // screens without a tall viewport, to increase the odds of every item
      // being visible:
      padding: $spacing-md;
    }

    &:hover {
      text-decoration: underline;
    }

    svg {
      color: $color-purple-40;
    }
  }

  .sign-up-button {
    display: block;
    font-weight: 400;
    text-align: center;
    width: 100%;
    padding: $spacing-md;
    background-color: $color-blue-50;
    border-radius: $border-radius-sm;
    margin: $spacing-sm auto;
  }
}

.user-info {
  display: flex;
  border-bottom: none;
  padding: $spacing-lg $spacing-md;
  background-image:
    linear-gradient(to bottom, #f9f9fb calc(100% - 3px), rgba(0, 0, 0, 0)),
    linear-gradient(90deg, #f73940 1.25%, #a83db5 96.87%);
  gap: $spacing-md;

  @media screen and (max-height: 700px) {
    // Because the menu is not scrollable (it stays in the same position while
    // the content behind it scrolls), this makes it take up less space on
    // screens without a tall viewport, to increase the odds of every item
    // being visible:
    padding: $spacing-md;
  }

  .user-avatar {
    border-radius: 50%;
  }

  span {
    display: flex;
    flex-direction: column;
  }

  .user-email {
    color: $color-blue-90;
    font-weight: 500;
  }

  .settings-link {
    flex-direction: row;
    display: flex;
    align-items: center;
    color: color.$grey-40;

    @include text-body-sm;
    gap: $spacing-sm;

    &:hover {
      text-decoration: underline;
    }

    svg {
      color: $color-purple-40;
    }
  }
}
