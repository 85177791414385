@use "~@mozilla-protocol/core/protocol/css/includes/lib" as *;
@use "../../../../styles/color";
@use "../../../../styles/text";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  padding: $spacing-md $spacing-md;

  img {
    width: 100%;
  }

  .content {
    padding: $spacing-lg 0;

    h2 {
      @include text.title-2xs {
        font-family: $font-stack-firefox;
        padding-bottom: $spacing-sm;
      }
    }

    .cta {
      padding-top: $spacing-sm;
    }
  }

  .still-alternative {
    display: none;
  }

  @media (prefers-reduced-motion) {
    video {
      display: none;
    }
    // Ideally a `display: none` on <source> elements would be enough to
    // cause the <img> inside <video> to be displayed, but it's not.
    // Hence, another <img> element:
    .still-alternative {
      display: inline-block;
    }
  }
}
