// These styles are taken from
// https://react-spectrum.adobe.com/react-aria/VisuallyHidden.html
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: 0 -1px -1px 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
