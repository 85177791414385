@use "~@mozilla-protocol/core/protocol/css/includes/lib" as *;
@use "../../../styles/color";

.site-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 $spacing-md;
  gap: $spacing-md;

  @media screen and (min-width: $content-xl) {
    padding: 0 $spacing-xl;
    gap: $spacing-lg;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: $font-stack-firefox;
  min-width: $spacing-2xl + $spacing-lg;
  color: inherit;

  &.is-active {
    height: 100%;
  }

  &.is-active::after {
    width: 100%;
    height: 4px;
    display: block;
    content: "";
    background: color.$firefoxGradient;
    position: absolute;
    bottom: 0;
  }
}

.menu-toggle {
  display: flex;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: currentColor;
  // webkit adds a padding-right: 1em to button elements
  padding: 0;

  @media screen and #{$mq-md} {
    display: none;
  }
}

.hidden-mobile {
  @media screen and (max-width: $screen-md) {
    display: none;
  }
}

.hidden-desktop {
  @media screen and (min-width: $screen-md) {
    display: none;
  }
}
