@use "../../../../styles/color";
@use "~@mozilla-protocol/core/protocol/css/includes/lib" as *;

.empty-message {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-lg;
  padding-bottom: $spacing-2xl;
  gap: $spacing-lg;
  align-items: center;
  text-align: center;

  p {
    @include text-body-md;
    max-width: 75%;
  }
}

.list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0 $spacing-md;
  max-height: min($content-md, 50vh);
  overflow-y: auto;

  li {
    padding: $spacing-sm 0;
    border-bottom: 1px solid $color-light-gray-30;

    &:last-child {
      border-bottom-style: none;
    }

    &:focus {
      outline: none;

      &:not(:hover) h3 {
        text-decoration: underline;
      }
    }

    .item {
      display: flex;
      align-items: center;
      gap: $spacing-md;
      border-radius: $border-radius-sm;
      padding: $spacing-xs 0;
      cursor: pointer;

      // Forces news icon to be the same size regardless of image size
      img {
        max-width: 50px;
        max-height: 50px;
      }

      h3 {
        @include text-body-sm;
        font-weight: 500;
      }

      p {
        @include text-body-sm;
      }

      &:hover {
        background-color: $color-violet-05;
        // An item is $hoverOverflow wider than its container when hovered,
        // i.e. the background colour is wider than the separator.
        $hoverOverflow: $spacing-sm;
        margin: 0 (-1 * $hoverOverflow);
        padding: $spacing-xs $hoverOverflow;
      }
    }
  }
}
